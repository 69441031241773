@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

html {
  font-family: "Inter var", "Inter", "system-ui", 'Open Sans', sans-serif;
  font-feature-settings: "cv02", "cv03", "cv04", "cv11";
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
}

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Clerk Authentication */

.mt-profile .cl-card > div:nth-child(3) {
  display: none;
}

.mt-signin .cl-card > div:nth-child(4) {
  display: none;
}

.mt-signin .cl-card > div:nth-child(5) {
  display: none;
}

.mt-signup .cl-card > div:nth-child(4) {
  display: none;
}


/* Toastify */
.Toastify__toast {
  border-radius: 11px !important;
  border: 1px solid #e5e7eb;
  font-size: 15px;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
}

.Toastify__toast-body {
  color: black;
  font-family: system-ui;
  font-weight: 450;
  padding: 5px 15px !important;
}

.Toastify__toast-icon {
  height: 25px !important;
  width: 25px !important;
}

.Toastify__close-button {
  opacity: 0.4;
  padding: 6px !important;
}


/* Default transition for all hoverable elements */
.hover\:scale-105 {
  transition: transform 0.1s ease-in-out;
}

/* Terms & Tooltips*/

.denthr-form .react-tooltip {
  padding: 20px 24px;
  border-radius: 20px;
  background-color: "#101828";
  font-family: "Inter var", system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  width: 350px;
  opacity: 1;
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
}

.backbutton {
  color: rgb(50, 50, 50) !important;
}

/* Tip Modal */
.mt-tipmodal h3 {
  color: black;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.mt-tipmodal h3 {
  color: black;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.mt-tipmodal h3:not(:first-child) {
  padding-top: 35px;
  margin-top: 35px;
  border-top: 1px solid rgb(233, 233, 233);
}

.mt-tipmodal a {
  color: rgb(40, 74, 228);
  font-weight: 600;
  text-decoration: underline;
}

.mt-tipmodal {
  color: rgb(73, 71, 71);
  line-height: 1.7;
}

/* Collapsible list animation */

.collapsible-list {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.5s ease-in-out;
  opacity: 1;
}

.collapsible-list.collapsed {
  max-height: 0;
  opacity: 0;
}

/* Gauge chart */
.gauge-denthr text {
  display: none !important;
}

.dataMeter {
  text-align: -webkit-center !important;
  text-align: center;
}

/* Markdown styling */
#markdown p, #markdown li {
  color: rgb(35, 35, 35);
  line-height: 1.7;
}

#markdown h1 {
  display: none;
}

#markdown h2 {
  margin-bottom: 0.5rem;
  margin-top: 2.3rem;
padding-top: 2.3rem;
border-top: 2px solid rgb(235, 235, 235);
  font-size: 24px;
  font-weight: 700;
}

#markdown h3 {
  margin-bottom: 1rem;
  margin-top: 2.8rem;
  font-size: 22px;
  font-weight: 700;
}

#markdown h4 {
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  font-size: 20px;
  font-weight: 700;
}

#markdown h5 {
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  font-size: 18px;
  font-weight: 700;
}

#markdown ul {
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

#markdown ul li {
  list-style-type: disc;
  list-style-position: outside;
  margin-left: 1rem;
  padding-left: 10px;
}

#markdown ol li {
  list-style-type: decimal;
  list-style-position: outside;
  margin-left: 1.3rem;
  padding-left: 10px;
}

#markdown li:not(:last-child) {
  margin-bottom: 3px;
}

/* AI Report */

.report-content.collapsed::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 120px; 
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.report-content.collapsed {
  max-height: 220px;
  overflow: hidden;
  position: relative;
  transition: all 0.8s ease-in-out;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

.report-content.expanded {
  max-height: 3500px; 
  transition: all 0.8s ease-in-out;
}

@media only screen and (max-width: 768px) {
  .report-content.expanded {
    max-height: 5000px;
  }
}


/* Gradient Text */
.gradientText {
  background-color: #F03D59;
    background-image: linear-gradient(45deg, #F03D59, #A973ED);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
}

/* Gradient border */
.module-border-wrap {
  padding: 2px;
  position: relative;
  background: linear-gradient(45deg, #f03d7c98, #aa73ed9e);
}

.module {
  background: white;
}

/* AI Summary: Markdown */
#summary-markdown ul {
  margin-top: 0.5rem;
  margin-left: 1.25rem;
}

#summary-markdown ul li {
  list-style-type: disc;
  list-style-position: outside;
  padding-left: 7px;
  /* margin-left: 0.3rem; */
}

#summary-markdown li:not(:last-child) {
  margin-bottom: 3px;
}

.tipModal p {
  margin-bottom: 2rem;
}